<template>
    <div>
        <v-card flat style="min-height: 70vh" v-if="is_self_route">
            <v-container class="py-0 px-0" fluid v-if="!errLoad">
                <v-card-title
                        class="subprimary headline"
                        style="min-height: 68px">
                    Онлайн оплата услуг
                </v-card-title>
                <v-col cols="12">
                    <v-data-iterator
                            :items="category"
                            :items-per-page.sync="category.length"
                            class="py-0"
                            hide-default-footer
                            no-data-text=""
                    >
                        <template v-slot:default="props">
                            <v-row class="justify-center">
                                <v-col
                                        :key="item.uid"
                                        class="py-2"
                                        cols="12"
                                        md="4" v-for="item in props.items" xl="3"
                                        xs="12"
                                >
                                    <v-hover v-slot="{ hover }">
                                        <v-card
                                                :elevation="hover ? 6 : 3"
                                                @click="selectCategory(item)" rounded="lg"
                                        >
                                            <v-img
                                                    :src='(item.image)? item.image:imgCart'
                                                    height="200px"
                                            >
                                                <v-card-text
                                                        class=" d-flex fill-height"
                                                        min-height="200px"
                                                >
                                                    <div v-if ="item.image" style="height: 200px; width: 100%; position: absolute; top: 0; left: 0; background: white; opacity: 0.5;">
                                                    </div>
                                                    <v-row
                                                            class="black--text title my-auto"
                                                            style="z-index: 10;"
                                                    >
                                                        <v-col
                                                                style="word-break: break-word; overflow-y: hidden; width: 100%;"
                                                        >
                                                            <div class="title"><span v-html="item.name"></span></div>
                                                            <div class="body-2"><span v-html="item.description"></span>
                                                            </div>
                                                        </v-col>
                                                    </v-row>
                                                </v-card-text>
                                            </v-img>
                                        </v-card>
                                    </v-hover>
                                </v-col>
                            </v-row>
                        </template>
                    </v-data-iterator>
                </v-col>
            </v-container>
            <v-container style="" v-else>
              <div class="d-flex justify-center align-center" style="min-height: 30pc">
                <v-alert type="info" outlined class="title">
                  По техническим причинам с 26 декабря 2024 г. по 06 января 2025 г.
                  <br>
                  <!--с 27 декабря 2023 г. по 06 января 2024 г. <br>-->
                  <!--                  с 29 декабря 2022г. по 07 января 2023г.-->
                  платежная система pay.muctr.ru работать не будет.<br>
<!--                  Ведутся технические работы.<br>-->
                  В случае необходимости произвести оплату можно через
                  любое приложение банка или в отделении банка в соответствии с графиком работы отделения.<br>
                  Приносим свои извинения за доставленные неудобства.<br>
                </v-alert>
              </div>
            </v-container>
        </v-card>
        <router-view :key="$route.fullPath" style="min-height: 70vh" v-else></router-view>
        <Footer></Footer>
    </div>

</template>

<script>
    import names from "@/modules/pay/routers/names";
    import urls from "@/urls/pay";
    import imgCart from "@/assets/fone.jpg"
    import Footer from "@/modules/pay/components/Footer.vue";
    import {isAuthenticated,isAliveToken} from "@/helper/auth.js";
    import {loadData, makeGetRequest} from "@/helper/requests";

    export default {
        name: "Services",
        components: {
            Footer
        },
        computed: {
          /**
           * Текущий роутер
           */
            is_self_route: function () {
                return this.$route.name === names.BASE
            },
          /**
           * Совместный список форм и категорий
           */
            category: function () {
                let mass = [];
                mass = mass.concat(this.items.filter(it => it.parentCategory == null))
                mass = mass.concat(this.form.filter(it => it.printPay == false))
                return mass
            },
        },
        data() {
            return {
                imgCart: imgCart,
                errLoad: false,
                items: [],
                form: [],
                parent: null,
                footer_height: 0,
            }
        },
        methods: {
          /**
           * Подгрузка форм без категории
           */
            loadForm() {
                let url = urls.PUBLIC.FORM.LIST(null, {category__isnull: true})
                loadData(url, (json) => {
                    this.form = json
                })
            },
          /**
           * Переход по карточки категории в зависимости о её типа
           *  @param {object} item - выбранная карточка на форме
           */
            selectCategory(item) {
                if (item.category === null)
                    this.$router.push({name: names.PUBLIC.FORM, params: {form: item.uid}})
                else
                    this.$router.push({name: names.PUBLIC.CATEGORY, params: {category: item.uid}})
            },
          /**
           * Установка бокового меню
           */
            setMenu(){
                let with_category_uid = (name) => {
                  return {name: name, params: {category: this.$route.params.category}}
                };
                let menu = []
                menu.push({
                  title: 'Перечень категорий',
                  icon: 'view_list',
                  included: true,
                  router: with_category_uid(names.BASE),
                })
                let sub = []
                //сборка всех категорий
                for (let obj of this.category) {
                  sub.push(
                      {
                        title: obj.name, icon: 'view_module',
                        router: {name: names.PUBLIC.CATEGORY, params: {category: obj.uid}}
                      }
                  )
                }
                menu.push({
                  title: 'Доступные категории', part: 'true', subs: sub
                })

                menu.push({
                  title: 'Мои транзакции',
                  icon: 'local_atm',
                  included: true,
                  router: names.PUBLIC.TRANZACTION,
                  name: names.PUBLIC.TRANZACTION,
                })
                // menu.push({
                //   title: 'Услуги печати',
                //   icon: 'print',
                //   included: false,
                //   router: names.PUBLIC.PRINT,
                //   name: names.PUBLIC.PRINT,
                // })
                this.$store.commit('leftmenu/replaceItems', menu);
              }
        },
        created() {
            let url = urls.PUBLIC.CATEGORY.LIST()
            if(!url)
                this.errLoad = true
            makeGetRequest(url).then(resp => {
                if (!resp.ok) {
                    this.errLoad = true
                }
                return resp.json()
            }).then(json => {
                this.items = json
                this.loadForm()
              if (isAuthenticated()) {
                this.setMenu();
              }else {
                this.$store.commit('leftmenu/dissable');
              }
            })
        }
    }
</script>

<style scoped>

</style>
